import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/login.css';

function Login(props) {
  props.setIsLoggedIn(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const userData = { email, password };
    fetch(process.env.REACT_APP_API_URL + '/api/v1/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    })
    .then(response => {
      // Handle successful login here
      if (response.ok) {
        response.text().then(text => {
          let user = JSON.parse(text);
          
          props.setIsLoggedIn(true);
          navigate('/home',  { state: { user } }); // Redirect to home page
        })
      }
    })
    .catch(error => {
      // Handle login error here
      console.log(error);
    });
  }

  return (
    <div id='login-cont'>
      <h1>Purchase Requisition</h1>
      <form id='login-form' onSubmit={handleSubmit}>
        <h3>Login</h3>
        <div>
          <label>
            Email: 
          </label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <br/>
        <div>
          <label>
            Password: 
          </label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <br/>
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;
