import React from 'react';

function Form(props) {
  return (
    <div className='payment'>
      <div>
        <label>Total requested in words: </label>
        <input type='text' id='total_requested_in_words' name='total_requested_in_words' required
        value={props.applicationDetails.total_requested_in_words} onChange={props.handleApplicationChange} />
        <label>Payment paid out to: </label>
        <input type='text' name='paid_out_to' required
        value={props.applicationDetails.paid_out_to} onChange={props.handleApplicationChange} />
      </div>
      <div>
        <label>Account Holder Name:</label>
        <input type='text' name='account_holder_name' required
        value={props.applicationDetails.account_holder_name} onChange={props.handleApplicationChange}  />
        <label>Bank Name:  </label>
        <input type='text' name='bank_name' required
         value={props.applicationDetails.bank_name} onChange={props.handleApplicationChange} />
      </div>
      <div>
        <label>Account Number: </label>
        <input type='text' name='account_number' required
        value={props.applicationDetails.account_number} onChange={props.handleApplicationChange} />
      </div>
    </div>
  );
}

export default Form;
