import React from 'react';
import '../../css/loading_screen.css';

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <div className="spinner"></div>
    </div>
  );
}

export default LoadingScreen;
