import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import '../../css/modal.css';

function CustomModal(props) {
  return (
    <Modal className="mod" show={props.show} onHide={props.handleClose}>
        <Modal.Header className="mod-header" closeButton>
            <Modal.Title>Submission Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mod-body">Send email if you're confident about your input data.</Modal.Body>
        <Modal.Footer className="mod-footer">
            <Button variant="secondary" className="mod-close-btn" onClick={props.handleClose}>
                Close
            </Button>
            <Button variant="primary" className="mod-submit-btn" onClick={props.handleSend}>
                Send Email
            </Button>
        </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;


