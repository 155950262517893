import React, { useState } from 'react';
// import axios from 'axios';
// import './Home.css';
// import './login.css';
import Login from './components/Login';
import Home from './pages/Home';
import Register from './components/Register';
import Logout from './components/Logout';

import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);

  function PrivateRoute({ element: Element, isAuthenticated, ...rest }) {
    return (
      <Route {...rest} element={
        isAuthenticated ? <Element /> : <Navigate to="/login" />
      } />
    );
  }
  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserData(null);
  };
  return (
    <Router>
      <Routes>
        {/* <> */}
          {/* {!isLoggedIn && ( */}
              <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            {/* )
          } */}
          {/* {isLoggedIn && ( */}
              <>
                <Route path="/home" element={<Home isLoggedIn={isLoggedIn} />} />
                <Route path="/register" element={<Register />} />
              </>
            {/* )
            
          } */}
          
        {/* </> */}
      </Routes>
    </Router>
  );
}

export default App;
