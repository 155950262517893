import React, { useState } from 'react';
import axios from 'axios';
import '../../css/Home.css';
import ApplicationDetails from '../../application_details';
import Table from '../../Table';
import PaymentDetails from '../../payment_details';
// import ApprovalDetails from './approval_details';
// import OfficeUse from './office_use';
import { API_URL } from "../../constants";
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingScreen from '../../components/loading_screen';
import CustomModal from '../../components/modal';

function Home(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state && location.state.user;

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // console.log('props.isLoggedIn: '+props.isLoggedIn);
  // if (!props.isLoggedIn) {
  //   navigate('/');
  // }
  let application_details_obj = {
    user_first_name: user.first_name,
    user_last_name: user.last_name,
    application_date: '',
    payment_due_date: '',
    expense_type: '',
    project_name: '',
    request_timeline: '',
    fieldwork_or_training: '',
    total_requested_in_words: '',
    paid_out_to: '',
    account_holder_name: '',
    bank_name: '',
    account_number: '',
    // age: '',
    // signature: null,
  };
  const [applicationDetails, setApplicationDetails] = useState(application_details_obj);

  let items_arr = [];
  for (let i = 0; i < 15; i++) {
    items_arr.push(
      { item_description: '', no_of_nights: '', no_of_people: '', quantity: '', unit_price: '', total: '', cost_centre: '' }
    );
  }
  const [items, setItems] = useState(items_arr);

  const [grand_total, setGrandTotal] = useState(0);

  const handleApplicationChange = (e) => {
    const { name, value, files } = e.target;
    // console.log(name+': '+value);
    setApplicationDetails((prevFormData) => ({
      ...prevFormData,
      [name]: files ? files[0] : value,
    }));
  };
  // const totObj = document.getElementVyId('total');
  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = (parseFloat(value)<=0)? '':value;
    // console.log(index+': '+field+': '+newItems[index][field]);
    
    if (field==='unit_price' || field==='quantity') {
      const u_price = parseFloat(newItems[index]['unit_price']);
      const n = parseFloat(newItems[index]['quantity']);
      if ((isNaN(u_price) || u_price===0) 
        || (isNaN(n) || n===0)) {
        newItems[index]['total'] = '';
      }else{
        if ((!isNaN(u_price) && u_price>0)
          && (!isNaN(n) && n>0)) {
          newItems[index]['total'] = (n*u_price).toFixed(2);
        }
      }
    }
    
    let tot = 0;
    newItems.forEach(v => {
      tot += parseFloat(v['total'] || 0);
    });
    setGrandTotal(tot.toFixed(2));
    setItems(newItems);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleShow();
  };

  const handleSend = () => {
    setLoading(true);
    const form = new FormData();
    for (const key in applicationDetails) {
      if (Object.hasOwnProperty.call(applicationDetails, key)) {
        form.append(key, applicationDetails[key]);
      }
    }
    form.append('grand_total', grand_total);
    form.append('items', JSON.stringify(items));
    // const response = await axios.post(API_URL, form);
    // // console.log(response.data);
    axios.post(API_URL, form, {
      responseType: 'blob', // Set the response type to blob
    })
    .then((response) => {
      handleClose();
      setLoading(false);
      setApplicationDetails(application_details_obj);
      setItems(items_arr);

      setGrandTotal(0);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', getFilename(response)); // Set the download attribute to the desired file name
      document.body.appendChild(link);
      // link.click();
    })
    .catch((error) => {
      handleClose();
      console.error(error);
    });
  };

  const getFilename = (response) => {
    const contentDisposition = response.headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    let filename = 'req_data.pdf';
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
    return filename;
  };
  return (
    <div className="Home">
        {loading && <LoadingScreen />}
        <CustomModal show={show} handleClose={handleClose} handleSend={handleSend} />
        <h1>Purchase Requisition</h1>
          {/* <div> */}
          <h4>All project requisitions must be approved by Project Mananger/ Director running the project.</h4>
          <form id="inputs" onSubmit={handleSubmit}>
              <ApplicationDetails applicationDetails={applicationDetails} handleApplicationChange={handleApplicationChange} />
              <Table items={items} grand_total={grand_total} handleItemChange={handleItemChange} />
              <PaymentDetails applicationDetails={applicationDetails} handleApplicationChange={handleApplicationChange} />
              <button type="submit">Confirm</button>
          </form>
          {/* </div> */}
    </div>
    
  );
}

export default Home;
