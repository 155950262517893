import React from 'react';

function Table(props) {
  // const numbers = Array.from({ length: 15 }, (_, i) => i + 1);
  return (
    <div id='tables-cont'>
      <div>
        <table>
          <thead>
            <tr>
              <th colSpan="6">Item Description</th>
              <th className='number-td'>No Of Nights</th>
              <th className='number-td'>No Of People</th>
              <th className='number-td'>Quantity</th>
              <th className='number-td'>Unit Price (R)</th>
              <th className='tot-td'>Total</th>
              <th id='cc' className='cc-td'>Cost Centre</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className='' id='table-form'>
        <table id='the-table'>
          <tbody>
            {props.items.map((item, index)=>
            <tr key={index}>
              <td colSpan="6">
                <input type='text' className='item_description' 
                  required={index===0}
                  value={item.item_description} 
                  onChange={(e) => props.handleItemChange(index, 'item_description', e.target.value)} 
                />
              </td>
              <td className='number-td'>
                <input type='number' 
                  value={item.no_of_nights} 
                  onChange={(e) => props.handleItemChange(index, 'no_of_nights', e.target.value)} 
                  min={0}
                />
              </td>
              <td className='number-td'>
                <input type='number' 
                  value={item.no_of_people} 
                  onChange={(e) => props.handleItemChange(index, 'no_of_people', e.target.value)} 
                  min={0}
                />
              </td>
              <td className='number-td'>
                <input type='number' 
                  value={item.quantity} 
                  required={index===0}
                  onChange={(e) => props.handleItemChange(index, 'quantity', e.target.value)} 
                  min={0}
                />
              </td>
              <td className='number-td'>
                <input type='number' 
                  value={item.unit_price} 
                  required={index===0}
                  onChange={(e) => props.handleItemChange(index, 'unit_price', e.target.value)} 
                  min={0.00}
                  step="0.01"
                />
              </td>
              <td className='tot-td'><input type='text' value={item.total} readOnly /></td>
              <td className='cc-td'>
                <input type='text' 
                  value={item.cost_centre} 
                  onChange={(e) => props.handleItemChange(index, 'cost_centre', e.target.value)} 
                />
              </td>
            </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <table>
          <tfoot>
            <tr>
              <th></th>
              <th className='number-td' id='total_label'colSpan="9">Total Requested:</th>
              <th className='tot-td'>R<span id='total'>{props.grand_total}</span></th>
              <th className='cc-td'></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default Table;
