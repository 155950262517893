import React, { useState } from 'react';

function Form(props) {
  const [showProjectDiv, setShowProjectDiv] = useState(false);

  const showProjectDivFunc = (event, val) => {
    setShowProjectDiv((val==='Project')? true:false);
    props.handleApplicationChange(event);
  }
  return (
    <div className='form application-div'>
      <div>
        <label>Application Date: </label>
        <input type='date' required name='application_date' value={props.applicationDetails.application_date} onChange={props.handleApplicationChange} />
        <br></br>
        <label>Payment Due Date: </label>
        <input type='date' required name='payment_due_date' value={props.applicationDetails.payment_due_date} onChange={props.handleApplicationChange} />
        <br></br>
        <label>Expense Type: </label>
        <select name='expense_type' required value={props.applicationDetails.expense_type} onChange={(event) => showProjectDivFunc(event, event.target.value)} >
          <option value={'-'}>--Select--</option>
          <option value={'Admin'}>Admin</option>
          <option value={'Project'}>Project</option>
        </select>
      </div>
      { showProjectDiv &&
        <div id='project_div'>
          <div id='proj_details'>
            <label>A: Project Name: </label>
            <input type='text' name='project_name' value={props.applicationDetails.project_name} onChange={props.handleApplicationChange} />
            <br></br>
            <label>B: Timeline of request:  </label>
            <input type='text' name='request_timeline' value={props.applicationDetails.request_timeline} onChange={props.handleApplicationChange} />
            <br></br>
            <label>C: Training/ Briefing OR Fieldwork: </label>
            <select name='fieldwork_or_training' value={props.applicationDetails.fieldwork_or_training} onChange={props.handleApplicationChange} >
              <option value={'-'}>--Select--</option>
              <option value={'Training/ Briefing'}>Training/ Briefing</option>
              <option value={'Fieldwork'}>Fieldwork</option>
            </select>
          </div>
        </div>
      }
    </div>
  );
}

export default Form;
